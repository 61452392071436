@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoppinsMedium";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Popins"),
    url("/public/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RammettoOne";
  src: local("Rametto"),
    url("/public/assets/fonts/RammettoOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CookieRunRegular";
  src: local("CookieRun"),
    url("/public/assets/fonts/CookieRun-Regular.otf") format("opentype");
}

body {
  height: min-content;
}

div,
span {
  font-family: "PoppinsLight";
}

.main-font-light {
  font-family: "PoppinsLight";
}

.main-font-medium {
  font-family: "PoppinsMedium";
}

.main-font-bold {
  font-family: "PoppinsBold";
}

.secondary-font-medium {
  font-family: "RammettoOne";
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.input-disabled {
  cursor: not-allowed;
}

.main-header {
  position: fixed;
  z-index: 99;
  top: 0;
  height: 70px;
}

.logo-header img {
  width: 105.62px !important;
}

.bg-yellow-linear {
  background: linear-gradient(180deg, #f9c744, #df8c00);
}

.bg-black-linear {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
}

.bg-black-linear-secondary {
  background: linear-gradient(180deg, #31313d 0%, #181718 100%);
}

.bg-black-linear-3 {
  background: linear-gradient(0deg, #25252c, #25252c);
}

.bg-black-linear-4 {
  background: linear-gradient(0deg, #2d3439, #2d3439);
}

.bg-black-primary {
  background: #1c1b1e;
}

.bg-black-secondary {
  background: #1c2023 !important;
}

.bg-black-secondary a {
  color: white !important;
  border-color: white;
}

.bg-black-3 {
  background: #1c1914 !important;
}

.border-grey-primary {
  border-color: #2e303e;
}

.vr {
  border: 1px solid #2e303e;
  transform: rotate(90deg);
}

.br {
  border: 1px solid #2e303e;
}

.br-2 {
  border: 2px solid #2e303e;
  background-color: #2e303e;
}

.bg-orange-wallet {
  background: #f6851b;
}

.bg-blue-wallet {
  background: #5493f7;
}

.bg-purple-wallet {
  background: #5332d1;
}

.bg-red-wallet {
  background: #c52627;
}

.bg-green-primary {
  background: #234224;
}

.bg-red-primary {
  background: #8f2929;
}

.text-white-light {
  color: #e1e2e9;
}

.text-white-light-f {
  color: #e1e2e9 !important;
}

.text-orange {
  color: #e89f01;
}

.border-orange {
  border-color: #e89f01;
}

.border-gray-2 {
  border-color: #727272;
}

.bg-orange {
  background-color: #e89f01;
}

.border-gray {
  border-color: #2e3438;
}

.text-red {
  color: #f56262;
}

.text-gray {
  color: #6c6d76;
}

.text-gray-bold {
  color: #a9a9a9;
}

.main-layout::before {
  background-attachment: fixed;
  background-size: cover;
  background-image: url("/public/assets/images/bg-image.png");
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: -1;
}

.text-uppercase {
  text-transform: uppercase;
}

.truncated-p {
  overflow: hidden;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.h-180 {
  height: 180px;
}

.custom-btn-color-yellow {
  background: #2d2412;
  color: #efb12a;
}

.custom-btn-color-red {
  background: #3f282b;
  color: #c24646;
}

.custom-btn-color-gray {
  background: #a3a3a3;
  color: #000000;
}

.custom-btn-color-purple {
  background: #2d2c60;
  color: #9997ff;
}

.custom-btn-color-purple-red {
  background: #312132;
  color: #ef2eb8;
}

.custom-btn-color-orange {
  background: #312e24;
  color: #ffae19;
}

.custom-btn-color-blue {
  background: #21323d;
  color: #0bb5fe;
}

.reward-border {
  border-color: #f7c340;
}

.text-border-brown {
  text-shadow: 2px 0 0 #5f3824, -2px 0 0 #5f3824, 0 2px 0 #5f3824,
    0 -2px 0 #5f3824, 1px 1px #5f3824, -1px -1px 0 #5f3824, 1px -1px 0 #5f3824,
    -1px 1px 0 #5f3824;
}

.reward-box-shadow {
  box-shadow: 0px 6px 30px rgba(255, 184, 119, 0.3),
    0px 6px 16px rgba(253, 204, 132, 0.2) !important;
}

.art-btn-yellow-content {
  background: linear-gradient(180deg, #f9c744 0%, #df8c00 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-yellow-content-reverse {
  background: linear-gradient(180deg, #df8c00 100%, #f9c744 0%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-yellow-container {
  background: #ae4b12;
  border: 1px solid #5f3824;
  /* border-radius: 6px; */
}

.art-btn-red-content {
  background: linear-gradient(180deg, #d55050 0%, #a43535 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-red-container {
  background: #610a0a;
  border: 1px solid #5f3824;
  /* border-radius: 6px; */
}

.art-btn-brown-content {
  background: linear-gradient(180deg, #be9c80 0%, #68492a 100%);
  box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
  /* border-radius: 6px 6px 9px 9px; */
}

.art-btn-brown-container {
  background: #44240e;
  border: 1px solid #714e3c;
  /* border-radius: 6px; */
}

.btn-disabled {
  opacity: 0.4;
}

/* override navbar item */
.ui.inverted.menu .active.item {
  background-color: unset;
  font-weight: 700;
  color: #e89f01 !important;
}

.ui.inverted.menu .active.item:hover {
  background-color: unset;
  color: #e89f01 !important;
}

.ui.inverted.menu .item:not(.hoverless):hover {
  color: #e89f01;
  background-color: unset;
}
.ui.inverted.menu .item:not(.hoverless):hover img {
  filter: invert(57%) sepia(29%) saturate(2462%) hue-rotate(12deg)
    brightness(103%) contrast(103%);
}
.hoverless img {
  filter: none !important;
}
.unfilter {
  filter: none !important;
}

.hover-orange:hover {
  color: #e89f01;
}
/* override navbar item */

textarea:focus,
input:focus {
  outline: none;
}

.text-black {
  color: #1a1917;
}

.main-container {
}

.ui.card > .content,
.ui.cards > .card > .content {
  padding: unset;
}

.navbar-md {
  justify-content: center;
}

.border-t-3 {
  border-top-width: 3px;
}

.h-min-32 {
  min-height: 3.5rem;
}

.text-red-2 {
  color: #d55050;
}

.hover-bg-linear-yellow:hover {
  background: #534735;
}

.ui.inverted.table {
  background-color: transparent;
}

.bg-gray {
  background-color: #292929;
  border-color: transparent !important;
}

.bg-gray-header.inverted.table th {
  background-color: #292929;
}

.no-border-header.inverted.table th {
  border-color: transparent !important;
}

.text-yellow {
  color: #ffa919;
}

.ui.table tr:last-child {
  pointer-events: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.padded.table td,
.ui.padded.table th {
  padding: 1em 1.5em;
}

.text-input-disabled {
  background-color: #bcbdc0 !important;
}

.dot {
  height: 3px;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
}

.ui.selection.dropdown .menu > .item {
  background-color: white;
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  border: 1px solid #2185d0;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  border: 1px solid #2185d0;
}

.link-disabled {
  pointer-events: none;
  color: gray !important;
}

.ui.dimmer {
  /* background-color: unset; */
}

.developing {
  opacity: 0.3;
  pointer-events: none;
}

.developing .opacity-1 {
  opacity: 1 !important;
}

.ui.selection.visible.dropdown > .text:not(.default) {
  color: unset;
}

.ui.pagination.menu .active.item {
  background-color: #333;
}

.font-cookie-run {
  color: #ebc276;
  font-family: "CookieRunRegular";
}

.bg-brown {
  background: #24211f !important;
}

.bg-brown-bold {
  background: #35332f;
}

.border-blue-brown {
  border-color: #1c2023;
}

.bg-black-light {
  background-color: #141416;
}

.ticket-container:hover .ticket-button {
  display: flex;
}

.ticket-button {
  display: none;
}

.ticket-container:hover .ticket-quantity {
  display: none;
}

.ticket-container:hover .card-header,
.ticket-container:hover img {
  /* z-index: -1; */
  opacity: 0.75;
}

.ticket-quantity {
  display: block;
}

.rc-slider-handle {
  cursor: pointer !important;
  transform: translateX(0%) translateY(1px) !important;
  width: 18px !important;
  height: 18px !important;
  z-index: 9;
}

.rc-slider,
.rc-slider-horizontal {
  transform: translateY(7px);
}

.rc-slider-rail,
.rc-slider-track {
  height: 9px !important;
  border-radius: 0 !important;
  z-index: 9;
}

.rc-slider-rail {
  transform: translateX(18px);
}

.rc-slider-handle {
  border-radius: 10% !important;
  opacity: 1 !important;
}

.rc-slider-handle:hover,
.rc-slider-handle:focus {
  border-width: 1px;
  border-color: #ebc276 !important;
  box-shadow: unset !important;
}

.decrease-switch {
  transform: translateX(4px);
  z-index: 9;
}
.increase-switch {
  transform: translateX(10px);
  z-index: 9;
}
.noHover {
  pointer-events: none !important;
}

.logo-text {
  display: flex;
  align-items: center;
}

.active-icon {
  filter: invert(57%) sepia(29%) saturate(2462%) hue-rotate(12deg)
    brightness(103%) contrast(103%);
}

.main-layout {
  margin-top: 30px;
}

.border-section {
  border-bottom: 1px solid #494949 !important;
}

.text-green-c {
  color: #40ca29;
}

.text-red-c {
  color: #cc4125;
}
.ui.vertical.menu {
  width: unset !important;
}
.absolute-center {
  left: 50%;
  transform: translate(-50%, 0);
}
.custom-input > input {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%) !important;
  color: white !important;
}
.custom-input > input:focus {
  border: 1px solid transparent !important;
}
.custom-input > input:hover {
  border: 1px solid transparent !important;
}
.custom-input > input:active {
  border: 1px solid transparent !important;
}
.custom-input-container {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%) !important;
  border: 1px solid #2e303e;
}
.scholar-table {
  min-height: calc(100vh - 240px);
}
.star-gray {
  filter: invert(84%) sepia(27%) saturate(1%) hue-rotate(30deg) brightness(96%)
    contrast(88%);
}
.nft-image {
  background-color: #141416;
  width: 130px;
  height: 130px;
}
.nft-plot-image {
  background-color: #141416;
  height: 100%;
  width: 180px;
}
.nft-plot-image .plot-img {
  /* width: 120px; */
  height: 120px;
  margin: auto;
  transform: translateY(30px);
}
.nft-troop {
  background-color: #141416;
  padding-top: 100%;
}
.ui.checkbox label {
  color: white !important;
}
.ui.checkbox label:focus {
  color: white !important;
}
.ui.checkbox input:checked ~ label:before {
  border: 1px solid #2e303e !important;
  padding: 10px !important;
}
.ui.checkbox .box:before,
.ui.checkbox label:before {
  border: 1px solid #2e303e !important;
  padding: 10px !important;
}
.setting .ui.checkbox input:checked ~ .box:after,
.setting .ui.checkbox input:checked ~ label:after {
  margin-top: 1px;
  margin-left: 2px;
  color: white !important;
}
.setting .ui.checkbox input:checked ~ label:before {
  border: 1px solid #2e303e !important;
  padding: 10px !important;
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%) !important;
}
.setting .ui.checkbox .box:before,
.setting .ui.checkbox label:before {
  border: 1px solid #2e303e !important;
  padding: 10px !important;
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%) !important;
  transform: translateY(-2px) translateX(-4px);
}
.nft-container > .ui.selection.dropdown {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
  border: 1px solid #2e303e;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
}

.menu-header > .ui.selection.dropdown {
  background-color: #24211f !important;
  border: unset;
}
.ui.dropdown > .dropdown.icon:before {
  /* display: none; */
  content: url("../public/assets/images/icons/select-icon.svg");
}
.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  margin: -1em !important;
}
.menu-header > .ui.selection.active.dropdown .menu {
  background: #24211f !important;
  border: unset !important;
  box-sizing: unset !important;
  color: white !important;
  padding: 0 !important;
}
.menu-header > .ui.selection.dropdown .menu > .item {
  background: #24211f !important;
  padding: 10px 14px !important;
  opacity: 0.6;
  margin: 0 0 !important;
  border-top: transparent !important;
  border-bottom: 1px solid black !important;
  color: white !important;
}
.menu-header > .ui.selection.dropdown .menu > .item:hover {
  background: #24211f !important;
  padding: 10px 14px !important;
  margin: 0 0 !important;
  opacity: 1;
  border-top: transparent !important;
  border-bottom: 1px solid black !important;
  color: white !important;
}
.menu-header > .ui.selection.dropdown .menu > .item img {
  filter: unset !important;
}
.menu-header > .ui.selection.dropdown .menu > .item:last-child {
  background: #24211f !important;
  padding: 10px 14px !important;
  margin: 0 0 !important;
  border-top: transparent !important;
  border-bottom: 0px solid #cecece !important;
  color: white !important;
}

.nft-container > .ui.selection.active.dropdown .menu {
  background: black !important;
  border: 1px solid #2e303e !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  color: white !important;
}
.nft-container > .ui.selection.dropdown .menu > .item {
  background: black !important;
  padding: 10px 10px !important;
  margin: 0 5px 0 5px;
  border-top: transparent !important;
  border-bottom: 1px solid #cecece !important;
  color: white !important;
}
.nft-container > .ui.selection.dropdown .menu > .item:last-child {
  background: black !important;
  padding: 10px 10px !important;
  margin: 0 5px 0 5px;
  border-top: transparent !important;
  border-bottom: 0px solid #cecece !important;
  color: white !important;
}
.nft-container > .ui.selection.dropdown .menu > .item:hover {
  background: linear-gradient(180deg, #101014 0%, #181e1d 100%) !important;
  color: #e89f01 !important;
}
.ui.menu .ui.dropdown .menu > .active.item {
  color: #e89f01 !important;
}

.ui.dropdown .menu .active.item {
  color: #e89f01 !important;
}
.menu-header {
  padding: 0 !important;
  margin: 0 !important;
}
.nft-card {
  max-width: 24rem;
  min-width: 17.2rem;
  height: 25rem;
  border-radius: 0.5625rem;
  margin: 0.25rem 0 1.25rem;
  padding: 0.75rem 0.625rem;
  position: relative;
  cursor: pointer;
}
.spine-player {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: 0 0;
  position: relative;
}
.spine-player * {
  box-sizing: border-box;
  font-family: "PT Sans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
  color: #ddd;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.spine-player canvas {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.spine-player #spine-player-button-logo {
  display: none;
}
.spine-player-button {
  display: none;
}

.cursor-disabled {
  cursor: not-allowed;
}

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: white !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: white !important;
}

.ui.checkbox input:checked ~ label:before {
  background-color: #e89f01 !important;
}

.animate-bounce-slight {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.dot-spin {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #ffffff, 12.72984px -12.72984px 0 0 #ffffff,
    18px 0 0 0 #ffffff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #ffffff, 12.72984px -12.72984px 0 0 #ffffff,
      18px 0 0 0 #ffffff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #ffffff, 18px 0 0 0 #ffffff,
      12.72984px 12.72984px 0 0 #ffffff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #ffffff,
      12.72984px 12.72984px 0 0 #ffffff, 0 18px 0 0 #ffffff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #ffffff,
      0 18px 0 0 #ffffff, -12.72984px 12.72984px 0 0 #ffffff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #ffffff,
      -12.72984px 12.72984px 0 0 #ffffff, -18px 0 0 0 #ffffff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #ffffff,
      -18px 0 0 0 #ffffff, -12.72984px -12.72984px 0 0 #ffffff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #ffffff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #ffffff,
      -12.72984px -12.72984px 0 0 #ffffff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #ffffff, 12.72984px -12.72984px 0 0 #ffffff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #ffffff;
  }
}

#menu-dropdown {
  margin-left: 12px;
  width: 10px !important;
  height: 6px !important;
}

.page-component:hover {
  border-radius: 4px;
  background: #323134;
}
.page-component:hover .text-header {
  color: #e89f01;
}
.page-component .text-header {
  font-family: "CookieRunRegular";
  font-weight: bold;
  color: #e1e2e9;
}

.active-filter {
  color: #e89f01;
  font-style: bold;
  background: #1c1b1e;
}

.select-chain {
  height: 48px;
  min-width: 120px !important;
  width: 120px !important;
  border-color: #e89f01 !important;
  border-width: 3px 0px 0px 0px !important;
  border-radius: 6px !important;
}